.loaderPlan {
  width: 30px;
  height: 30px;
  border: 3px solid #040000;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: absolute;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.tooltip-inner {
  max-width: 260px;
}

.hideForm {
  display: none;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.border-right {
  border-right: 1px solid #dee2e6; /* Add a border to the right side of the cell */
}

.plan_title {
  width: 100%;
  height: 60px;
  font-size: 32px;
  line-height: 44px;
  margin-top: 60px;
  margin-bottom: 4px;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.intervention-info {
  font-family: "Montserrat";
  line-height: 1.4;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  position: sticky;
  top: 125px;
  z-index: 2;

  /* Updated to be opaque */
  background-color:  rgb(240, 241, 242);
  /* or alternatively: background-color: rgb(108, 117, 125); */
  
  border: 1px solid #6c757d;
  padding: 1rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.intervention-info p {
  font-style: normal;
  color: #000000;
  margin-bottom: 4px;
  letter-spacing: 0.07em;
}

@media screen and (min-height: 600px) and (max-height: 1000px) {
  .intervention-info {
    top: 15%;
  }
}

.container-details {
  width: 80%;
  background-color: white;
  margin: 0 auto;
  max-width: 1440px;
  font-family: "Montserrat";
  color: #000000;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.07em;
  word-wrap: break-word;
}

.exercise-label {
  font-size: 12px;
  font-weight: 600;
}

.cardio-label-low::before {
  content: "Low Intensity Cardio";
}

.cardio-label-low::after {
  content: "!";
  font-size: 0.7em;
  vertical-align: super;
}

@media (max-width: 499px) {
  .cardio-label-low::before {
    content: "Low Intensity Cardio: Min / Week ";
  }
}

.cardio-label-medium::before {
  content: "Medium Intensity Cardio";
}

.cardio-label-medium::after {
  content: "!";
  font-size: 0.7em;
  vertical-align: super;
}

@media (max-width: 499px) {
  .cardio-label-medium::before {
    content: "Medium Intensity Cardio: Min / Week ";
  }
}

.cardio-label-high::before {
  content: "High Intensity Cardio";
}

.cardio-label-high::after {
  content: "!";
  font-size: 0.7em;
  vertical-align: super;
}

@media (max-width: 499px) {
  .cardio-label-high::before {
    content: "High Intensity Cardio: Min / Week ";
  }
}

.strength-training-label::before {
  content: "Strength Training";
}
@media (max-width: 499px) {
  .strength-training-label::before {
    content: "Strength Training: Times / Week";
  }
}

.balance-label::before {
  content: "Balance";
}
@media (max-width: 499px) {
  .balance-label::before {
    content: "Balance: Times / Week";
  }
}

.bone-density-label::before {
  content: "Bone Density";
}
@media (max-width: 499px) {
  .bone-density-label::before {
    content: "Bone Density: Times / Week";
  }
}

.stretching-label::before {
  content: "Stretching";
}
@media (max-width: 499px) {
  .stretching-label::before {
    content: "Stretching: Times / Week";
  }
}

.sauna-frequency-label::before {
  content: "Sauna Frequency";
}
@media (max-width: 499px) {
  .stretching-label::before {
    content: "Sauna Frequency: Times / Week";
  }
}

.sauna-duration-label::before {
  content: "Sauna Duration";
}
@media (max-width: 499px) {
  .stretching-label::before {
    content: "Sauna Duration: Minutes";
  }
}

@media (max-width: 499px) {
  .exercise-label {
    display: none;
  }
}

@media (min-width: 500px) {
  .exercise-label {
    display: block;
  }
}

@media (min-width: 300px) {
  .label-font {
    font-size: 13px;
  }
}

@media (min-width: 600px) {
  .label-font {
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .label-font {
    font-size: 15px;
  }
}

@media (min-width: 1200px) {
  .label-font {
    font-size: 17px;
  }
}
@media (min-width: 1400px) {
  .label-font {
    font-size: 19px;
  }
}

.plan-input {
  font-size: 15px;
  flex-shrink: 0;
  width: 50px;
  height: 37px;
  border: 1px solid #000000;
  border-radius: 10px;
  overflow: hidden;
}

@media (min-width: 300px) {
  .plan-input {
    width: 24px;
    height: 20px;
    font-size: 9px;
  }
}

@media (min-width: 365px) {
  .plan-input {
    width: 35px;
    height: 27px;
    font-size: 12px;
  }
}

@media (min-width: 600px) {
  .plan-input {
    width: 50px;
    height: 37px;
    font-size: 15px;
  }
}

@media (min-width: 992px) {
  .plan-input {
    width: 45px;
    height: 30px;
    font-size: 13px;
  }
}

@media (min-width: 1200px) {
  .plan-input {
    width: 50px;
    height: 37px;
    font-size: 15px;
  }
}

.heading-label {
  font-size: 14px;
}

@media (min-width: 300px) {
  .heading-label {
    font-size: 8px;
  }
}

@media (min-width: 365px) {
  .heading-label {
    font-size: 10px;
  }
}

@media (min-width: 600px) {
  .heading-label {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .heading-label {
    font-size: 13px;
  }
}

@media (min-width: 1200px) {
  .heading-label {
    font-size: 14px;
  }
}

.supplement-box {
  flex-shrink: 0;
  border: 1px solid #000000;
  border-radius: 10px;
  overflow: hidden;
}

@media (min-width: 300px) {
  .supplement-box {
    width: 15px;
    height: 15px;
  }
}

@media (min-width: 600px) {
  .supplement-box {
    width: 22px;
    height: 22px;
  }
}

@media (min-width: 992px) {
  .supplement-box {
    width: 20px;
    height: 20px;
  }
}

@media (min-width: 1200px) {
  .supplement-box {
    width: 22px;
    height: 22px;
  }
}

@media (min-width: 300px) {
  .supplement-box-margin {
    margin-right: 30px;
  }
}

@media (min-width: 365px) {
  .supplement-box-margin {
    margin-right: 40px;
  }
}

@media (min-width: 600px) {
  .supplement-box-margin {
    margin-right: 45px;
  }
}

@media (min-width: 300px) {
  .supplement-validation-box-margin {
    margin: 5px 0px 5px 15px;
  }
}

@media (min-width: 365px) {
  .supplement-validation-box-margin {
    margin: 5px 0px 5px 15px;
  }
}

@media (min-width: 600px) {
  .supplement-validation-box-margin {
    margin: 6px 0px 5px 15px;
    /* margin: 30% 0% 10% 50%; */
  }
}

.sleep-box {
  border: 1px solid #000000;
  border-radius: 10px;
}

@media (min-width: 365px) {
  .sleep-box {
    width: 54px;
    height: 30px;
    font-size: 8px;
  }
}

@media (min-width: 600px) {
  .sleep-box {
    width: 63px;
    height: 40px;
    font-size: 15px;
  }
}

@media (min-width: 992px) {
  .sleep-box {
    font-size: 13px;
    width: 58px;
    height: 40px;
  }
}

@media (min-width: 1200px) {
  .sleep-box {
    font-size: 14px;
    width: 63px;
    height: 40px;
  }
}

.hover-position {
  position: relative;
  z-index: 0;
}

.hover-supplement-text {
  display: none;
  top: 100%;
  left: 50%;
}

@media (min-width: 350px) {
  .hover-supplement:hover + .hover-supplement-text {
    display: block;
    font-size: 9px;
    font-weight: 600;
  }
}

@media (min-width: 550px) {
  .hover-supplement:hover + .hover-supplement-text {
    display: block;
    font-size: 13px;
    font-weight: 600;
  }
}

@media (min-width: 991px) {
  .hover-supplement:hover + .hover-supplement-text {
    display: block;
    font-size: 11px;
    font-weight: 600;
  }
}

@media (min-width: 1200px) {
  .hover-supplement:hover + .hover-supplement-text {
    display: block;
    font-size: 14px;
    font-weight: 600;
  }
}
