.refresh-button {
  position: relative;
  transform: translateY(-50%);
  padding: 8px 22px;
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
  max-width: 100%; 
  width: auto; 
}

.refresh-button:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .refresh-button {
    font-size: 18px;
    padding: 8px 16px; 
  }
}

@media (max-width: 480px) {
  .refresh-button {
    font-size: 16px; 
    padding: 6px 12px; 
  }
}
