.highlight {
	background-color: #da2828 !important;
	color: rgb(248, 246, 246) !important;
}

.dot-container {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	height: 100%;
	position: relative;
}

.dot {
	width: 6px;
	height: 6px;
	background-color: rgb(208, 147, 147);
	border-radius: 50%;
	position: absolute;
	margin-bottom: 50px; 
}

.react-calendar {
	width: 1000px;
	max-width: 100%;
	background: white;
	border: 1px solid #000;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	font-family: Arial, Helvetica, sans-serif;
	line-height: 2.5em;
}

.react-calendar--doubleView {
	width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
	display: flex;
	margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
	width: 50%;
	margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	/* color: black; */
}

.react-calendar button {
	margin: 0;
	border: 0;
	outline: none;
}

.react-calendar button:enabled:hover {
	cursor: pointer;
}

.react-calendar__navigation {
	display: flex;
	justify-content: space-between;
	
	height: 46px;
	margin-bottom: 1em;
	color: black;
}


.react-calendar__navigation button {
	min-width: 54px;
	padding: 0px;
  margin: 0.6px; 
	background: none;
	color: rgb(0, 0, 0); 
	font-weight: 550;
}

.react-calendar__navigation button:disabled {
	background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
	background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
	text-align: center;
	text-transform: uppercase;
	font: inherit;
	font-size: 0.75em;
	font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
	padding: 0.7em;
	
	
}


.react-calendar__month-view__weekNumbers .react-calendar__tile {
	display: flex;
	align-items: center;
	justify-content: center;
	font: inherit;
	font-size: 0.75em;
	font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
    cursor: default;
}

.react-calendar__month-view__days__day--weekend {
	color: #757575;
}

.react-calendar__month-view__days__day--neighboringMonth {
	color: #757575;
}

.react-calendar__year-view .react-calendar__tile ,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
	padding: 2em 0.5em;
	width: 350px; 
    height: auto; 
}


.react-calendar__tile {
	max-width: 100%;
	padding: 10px 6.6667px;
	background: none;
	text-align: center;
	line-height: 16px;
	font: inherit;
	font-size: 0.833em;
	color: black;
}

.react-calendar__tile:disabled {
	background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
	background-color: #e6e6e6;
}

.react-calendar__tile--now {
	background: lightblue;
	color: black;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
	background: #b3d9ff;
}

.react-calendar__tile--hasActive {
	background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
	background: #a9d4ff;
}

.react-calendar__tile--active {
	background: #006edc;
	color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
	background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
	background-color: #e6e6e6;
}

.logged-date {
	background-color: lightgreen !important;
	color: black;
}

.missed-date {
	/* background-color: lightcoral !important; */
	color: black;
}

.today-date {
	background-color: lightblue !important;
	color: black;
}

.weekend-date {
	/* background-color: #f0f0f0 !important; */
	color: rgb(186, 7, 7);
}

.missed-date.weekend-date {
    /* background-color: lightcoral !important;  */
    color: rgb(186, 7, 7); 
}


@media (max-width: 1600px) {
	.react-calendar {
		width: 100%;
	}
}

.CalendarForm {
	display: flex;
	justify-content: center;
	margin-bottom: 120px;
}

.CalendarCom {
	margin: 0px 20px;
	position: sticky;
	height: 30rem;
	top: 5rem;
	z-index: 1;
}

.FormCom {
	margin: 0px 20px;
}

@media (max-width: 1200px) {
	.CalendarForm {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.CalendarCom,
	.FormCom {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
}
