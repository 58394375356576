.premium-footer {
  padding: 10px 40px 10px 40px;
  font-family: 'Montserrat';
  font-size: 16px;
  color: black;
  z-index: 2;
  background-color: white;
  position: fixed;
  bottom: 55px;
  left: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.non-premium-footer {
  padding: 10px 40px 10px 40px;
  font-family: 'Montserrat';
  font-size: 16px;
  color: black;
  z-index: 2;
  background-color: white;
  position: fixed;
  bottom: 90px;
  left: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.footer.visible {
  opacity: 1;
  pointer-events: auto;
}

.footer-content div {
display: flex;
justify-content: space-between; /* Aligns items on the left and right */
align-items: center; /* Centers items vertically */
flex-wrap: wrap; /* Allows wrapping for small screens */
}

.footer.hidden {
  opacity: 0;
  pointer-events: none;
}

.footer:hover {
  opacity: 1;
  pointer-events: auto;
}

.copyright {
text-align: right;
white-space: nowrap; /* Prevents text wrapping */
font-size: 14px;
}

.seperator {
  width: 100%;
  height: 0;
  border: 1px solid #CED0CE;
  margin-bottom: 15px;
}
