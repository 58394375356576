.message-area {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  overflow: hidden;
  padding: 2px;
  text-align: center;
  z-index: 1000; /* Ensure it stays above other elements */
  border: 1px solid #fff;
}

.message-container {
  display: flex;
  flex-direction: column-reverse; /* Display messages in reverse order */
  height: 100%;
  overflow: hidden;
}

.message {
  padding: 5px;
  margin: 5px;
  /* border: 1px solid #fff; */
}

.message-text {
  font-size: '18px';    /* Larger font size */
  font-weight: 'bold'; /* Thicker text */
  margin: '8px 0';    /* Add some margin between messages */
  color: '#333',      /* Darker text color for better readability */
}