.body_form {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #6c3b3b;
  color: #ffffff;
  font-size: 14px;
}
.cognito_login_box {
  background-color: #fff;
  width: 350px;
  margin: auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 70px;
}

.cognito_login_title {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}
.cognito_login_text {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.cognito_login_label {
  margin-top: 10px;
  display: block;
  color: #333;
}

.cognito_lable_box {
  width: calc(100% - 20px);
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.cognito_login_submit {
  background-color: #5c6bc0;
  color: #fff;
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.cognito_login_submit:hover {
  background-color: #3f51b5;
}

.cognito_login_error {
  color: #d32f2f;
  margin-top: 10px;
}

.cognito_login_note {
  margin-top: 10px;
  font-size: 12px;
  color: #666;
}
.inputField-customizable {
  width: 100%;
  height: 34px;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  margin-bottom: 30px;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 50%;
  max-width: 600px;
  max-height: 80%;
  overflow-y: auto;
}

@media (max-width: 600px) {
  .modal-content {
    width: 90%;
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  opacity: 0.8;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button:hover {
  opacity: 1;
}

.close-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px #ffcccb;
}

button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.cognito_login_error {
  color: red;
  margin-top: 10px;
}

.timezone-dropdown {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #a69595;
  background-color: white;
  z-index: 1000;
}

.popup {
  position: fixed; 
  top: 0%; 
  left: 50%;
  transform: translate(-50%, 140%); 
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex; 
  justify-content: center;
  align-items: center;
  z-index: 1000; 
  width: 100%; 
  height: 50%; 
}

.popup-content {
  background: white; 
  padding: 20px; 
  border-radius: 10px; 
  text-align: center; 
  max-width: 500px; 
  width: 90%;
}

.social-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Space between the icon and text */
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
  color: rgb(70, 56, 56);
  background-color: #ffffff;
  
}

.social-login-button:hover {
  background-color: #5882be;
}



