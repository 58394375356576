/*CSS Elements for Benefits table, collapsed and not collapsed*/

 @media (max-width: 991px) {
    .plan-collapse {
      display: block;
    }
  }
  
  @media (min-width:992px) {
    .plan-collapse {
      display: none;
    }
  }

  .sticky-collapse {
    height:30px;
    position:sticky;
    top:90px;
    z-index:1;
  }

  
  /*for normal table, not collapsed*/
  .sticky-property {
    height: 30px;
    position: sticky;
    margin-top: 10px;
    top: var(--sticky-top, calc(15% + 62px));

    .row {
      background-color: rgba(108, 117, 125, 0.1);
    }
  }
  
  .collapse-table-background {
    background-color: white;
    transition: all 0.2s ease;
    z-index:0; 
  }
  
  @media (min-width:300px) {
    .margin-on-collapse {
      margin-top: 210px;
    }
  }
  @media (min-width:400px) {
    .margin-on-collapse {
      margin-top: 200px;
    }
  }
  @media (min-width:500px) {
    .margin-on-collapse {
      margin-top: 275px;
    }
  }



  .curr_label::before {
    content:"Current"
  }
  @media (max-width:600px) {
    .curr_label::before {
      content:"Curr"
    }
  }
  
  .opt_label::before {
    content:"Optimal"
  }
  @media (max-width:600px) {
    .opt_label::before {
      content:"Opt"
    }
  }
  
  
  
  @media (min-width:250px) {
    .table-background {
      font-size: 9px; 
    }
  }
  @media (min-width:500px) {
    .table-background {
      font-size: 13px; 
    }
  }
  @media (min-width:600px) {
    .table-background {
      font-size: 13px; 
    }
  }
  @media (min-width:700px) {
    .table-background {
      font-size: 15px; 
    }
  }
  @media (min-width: 992px) {
    .table-background {
      font-size: 7px
    }
  }
  @media (min-width: 1150px) {
    .table-background {
      font-size: 10px
    }
  }
  @media (min-width: 1225px) {
    .table-background {
      font-size: 0.9vw;
    }
  }
  
  @media (min-width: 250px) {
    .benefit-label-font {
      font-size: 9px
  
    }
  }
  
  @media (min-width: 500px) {
    .benefit-label-font {
      font-size: 13px
  
    }
  }
  @media (min-width: 600px) {
    .benefit-label-font {
      font-size: 14px
  
    }
  }
  @media (min-width: 750px) {
    .benefit-label-font {
      font-size: 15px
  
    }
  }
  
  @media (min-width: 992px) {
    .benefit-label-font {
      font-size: 1vw
  
    }
  }
  
  .view_benefit_button {
    width: 200px; 
    font-size: 15px;
  }
  @media (max-width: 500px) {
    .view_benefit_button {
      width:120px;
      font-size:10px;
    }
  }  
  .benefits-table {
    margin-top: 0px;
    padding-top: 0px;
  }
  .note {
    margin-top: 10px; 
  }
  