.form-control:focus {
    border-color: #04723A; /* Highlight on focus */
    outline: none;
  }

.general-health-container {
  min-width: 800px;
  width: 60%;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.general-health{
  max-width: 30%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s;
  margin-left: auto;
  margin-right: 0; 
  width: "250px"
}