body {
  font-family: "Montserrat", sans-serif;
  background-color: #f8f9fa; /* Light background for contrast */
}

.pitt-sleep-container {
  min-width: 800px;
  width: 60%;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h3 {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #333;
  max-width: 75%; /* Limit width to 75% of the container */
  margin-left: auto; /* Center the text */
  margin-right: auto; /* Center the text */
}

.instructions {
  margin-bottom: 20px;
  font-size: 16px;
  color: #555;
  text-align: center;
  max-width: 75%; /* Limit width to 75% of the container */
  margin-left: auto; /* Center the text */
  margin-right: auto; /* Center the text */
}

.form-group {
  margin-bottom: 20px; /* Space between questions */
}

.form-group label {
  display: block;
  margin-bottom: 8px; /* Space between label and input */
  color: #333;
}

/* .form-control {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s;
  max-width: 100%;
  margin-left: auto; Align to the right
  margin-right: 0; Ensure no margin on the right
} */

.form-control:focus {
  border-color: #04723A; /* Highlight on focus */
  outline: none;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.score {
  text-align: center;
  /* margin-top: 30px; */
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 70px;
}

.score-description {
  color: gray;
  font-size: 14px;
  text-align: center;
}

.form-label {
    max-width: 63%;
}

.fixed_header {
  position: fixed;
  min-width: 800px;
  width: 60%;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1)
}

.pitt-sleep{
  max-width: 30%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s;
  margin-left: auto;
  margin-right: 0; 
}